import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Menu = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 14a1 1 0 010 2H3a1 1 0 010-2zm0-6a1 1 0 010 2H3a1 1 0 110-2z" fill="currentColor" fillRule="nonzero" />
  </StyledSvg>
);
export default Menu;
